/* FAQSection.css */
/* Add your custom styles for the FAQ section using Tailwind CSS or regular CSS */
/* Example styles */
.ant-collapse {
    background-color: #ffffff;
    border-radius: 30px;
    border: 1px #ff6537;
  }
  
  .ant-collapse-header {
    background-color: #ffffff;
    font-weight: 600;
    color: #333;
  }
  