.card-exam{
    backdrop-filter: blur(21px) saturate(80%);
    -webkit-backdrop-filter: blur(21px) saturate(80%);
    background-color: rgba(255, 255, 255, 0.68);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
}
// .bg {
//     background-color: #ffffff;
//     background-image: 
//         radial-gradient(at 57% 53%, hsl(344, 100%, 66%) 0, transparent 59%), 
//         radial-gradient(at 42% 85%, hsl(340.80, 0%, 100%) 0, transparent 55%);
// }