

.contact img {
  width: 92%;
}
.contact h2 {
    font-size: 45px;
    font-weight: 700;
  margin-bottom: 30px;
}
.contact form input{
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  color: #000000;
  margin: 0 0 8px 0;
  padding: 10px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form textarea{
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  color: #000000;  
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #000000;
  opacity: 0.8;
}
